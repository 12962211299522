import React from "react";
import * as Yup from "yup";
import CrmForm from "./CrmForm";
import Submit from "./Submit";

export default function Create() {
  const data = {
    title: "",
    name: "",
    clientId: "",
    clientSecret: "",
    redirectUri: "",
    roistatToken: "",
    authType: "none",
    authToken: "",
  };
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Введите название санатория"),
    name: Yup.string().required("Введите имя CRM"),
    clientId: Yup.string().required("Введите секретный ключ"),
    clientSecret: Yup.string().required("Введите ID интеграции"),
    redirectUri: Yup.string().required("Введите ссылку для перенаправления"),
    roistatToken: Yup.string(),
    authType: Yup.string(),
    authToken: Yup.string(),
  });

  const formik = Submit({
    submitMethod: "post",
    submitUrl: "crm-account",
    data,
    validationSchema,
  });

  return (
    <CrmForm
      values={formik.values}
      errors={formik.errors}
      responseError={formik.status}
      disableSubmit={formik.isSubmitting}
      handleChange={formik.handleChange}
      handleSubmit={formik.handleSubmit}
    />
  );
}
