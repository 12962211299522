import React from "react";
import classNames from "classnames";

export default function Button({ inline, block, icon, buttonText, onClick }) {
  return (
    <button
      className={classNames({
        "text-indigo-600 text-sm font-medium hover:text-indigo-900": inline,
        "px-4 bg-indigo-500 p-3 rounded-lg text-white text-sm hover:bg-indigo-400 w-max": block,
        "text-indigo-600 text-lg px-3 font-medium hover:text-indigo-900 focus:outline-none": icon,
      })}
      onClick={onClick}
    >
      {buttonText}
    </button>
  );
}
