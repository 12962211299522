import React from "react";
import ModalHeader from "./ModalHeader";
import ModalTemplate from "./ModalTemplate";

export default function Modal({ title, children, onClose }) {
  return (
    <ModalTemplate onClose={onClose}>
      <ModalHeader title={title} onClose={onClose} />
      {children}
    </ModalTemplate>
  );
}
