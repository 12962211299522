import React from "react";
import Activate from "../forms/Activate";
import OpenModalButton from "./OpenModalButton";

export default function ActivateModalButton({ crmAccount }) {
  const icon = <i className="fas fa-plug" title="Подключить"></i>;

  return (
    <OpenModalButton icon buttonText={icon} title={`Активация аккаунта ${crmAccount.title} (${crmAccount.name})`}>
      <Activate crmAccount={crmAccount} />
    </OpenModalButton>
  );
}
