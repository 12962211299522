import React from "react";
import { Popover } from '@headlessui/react'
import { CopyToClipboard } from 'react-copy-to-clipboard';

export default function FieldWithPopover({ text, popoverText }) {
  const textStyles = {
    border: '1px solid #ddd',
    maxHeight: '300px',
  }

  return (
    text && <Popover>
      <Popover.Button className="text-blue-700 underline text-decoration-dashed text-decoration-thin">{text}</Popover.Button>
      <Popover.Panel className="absolute z-10">
        <div
          className="relative bg-white border-0 block z-50 font-normal leading-normal text-sm text-left no-underline break-words rounded-lg shadow text-gray-700 font-normal px-4 pb-4 whitespace-normal whitespace-pre overflow-y-scroll max-w-none"
          style={textStyles}>
          <CopyToClipboard text={popoverText}>
            <div>
              <button className="text-blue-700 underline text-decoration-dashed text-decoration-thin">Скопировать</button>
            </div>
          </CopyToClipboard>
          {popoverText}
        </div>
      </Popover.Panel>
    </Popover>
  )
}