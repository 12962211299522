import classNames from "classnames";
import React from "react";

export default function FormControls({ attention, onSubmit, submitName, disableSubmit }) {
  return (
    <div className="flex justify-end pt-2 pb-2">
      <button
        onClick={onSubmit}
        type="submit"
        disabled={disableSubmit}
        className={classNames({
          "modal-close px-4 bg-red-500 p-3 rounded-lg text-white hover:bg-red-400": attention,
          "modal-close px-4 bg-indigo-500 p-3 rounded-lg text-white hover:bg-indigo-400": !attention,
        })}
      >
        {submitName}
      </button>
    </div>
  );
}
