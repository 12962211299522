import React from "react";
import classNames from "classnames";

const crmStatus = {
  Active: "Активен",
  Disabled: "Отключен",
  ConfigurationRequired: "Требуется конфигурация",
  AuthorizationRequired: "Требуется авторизация",
};

export default function CrmAccountStatusBadge({ status }) {
  return (
    <span
      className={
        "px-2 inline-flex text-sm leading-5 font-medium rounded-full " +
        classNames({
          "bg-green-100 text-green-800": status === "Active",
          "bg-yellow-100 text-yellow-800": status === "AuthorizationRequired",
          "bg-red-100 text-red-800": status === "ConfigurationRequired",
          "bg-gray-100 text-gray-800": status === "Disabled",
        })
      }
    >
      {crmStatus[status]}
    </span>
  );
}
