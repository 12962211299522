import React from "react";
import { useHistory } from "react-router";
import FormField from "../../common/form/FormField";
import FormGroup from "../../common/form/FormGroup";
import FormLabel from "../../common/form/FormLabel";
import FormTextInput from "../../common/form/FormTextInput";
import { useFormik } from "formik";
import Button from "../../common/Button";
import { useQuery } from "../../../modules/useQuery";
import { generateQueryString } from "../../helpers/queryString";

export default function LeadSearchForm() {
  const history = useHistory();
  const query = useQuery();
  const leadId = query.get("lead_id") || "";

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: { leadId },
    enableReinitialize: true,
    onSubmit: ({ leadId }) => {
      history.push(generateQueryString({ leadId: leadId || undefined }));
    },
  });

  const searchIcon = <i className="fas fa-search" title="Найти"></i>;

  return (
    <FormGroup>
      <FormField>
        <FormLabel>Номер сделки:</FormLabel>
        <FormTextInput id="leadId" name="leadId" placeholder="12345678" value={values.leadId} onChange={handleChange} />
        <Button icon buttonText={searchIcon} onClick={handleSubmit} type="submit" />
      </FormField>
    </FormGroup>
  );
}
