import React from "react";
import ActivateForm from "./ActivateForm";
import Submit from "./Submit";

export default function Activate({ crmAccount }) {
  const formik = Submit({
    submitMethod: "put",
    submitUrl: `crm-account/${crmAccount.id}/enabled`,
  });

  return (
    <ActivateForm
      crm={crmAccount.title}
      responseError={formik.status}
      disableSubmit={formik.isSubmitting}
      handleChange={formik.handleChange}
      handleSubmit={formik.handleSubmit}
    />
  );
}
