import React from "react";
import DeactivateForm from "./DeactivateForm";
import Submit from "./Submit";

export default function Deactivate({ crmAccount }) {
  const formik = Submit({
    submitMethod: "delete",
    submitUrl: `crm-account/${crmAccount.id}`,
  });

  return (
    <DeactivateForm
      crm={crmAccount.title}
      responseError={formik.status}
      disableSubmit={formik.isSubmitting}
      handleChange={formik.handleChange}
      handleSubmit={formik.handleSubmit}
    />
  );
}
