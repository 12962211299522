import React from "react";
import OpenModalButton from "./OpenModalButton";
import Create from "../forms/Create";

export default function CreateModalButton() {
  return (
    <OpenModalButton
      block
      buttonText={
        <>
          <i className="fas fa-plus pr-1"></i> Добавить
        </>
      }
      title="Добавление аккаунта"
    >
      <Create />
    </OpenModalButton>
  );
}
