import React from "react";
import Heading from "../../common/Heading";
import HeadingContainer from "../../common/HeadingContainer";
import Layout from "../../common/Layout";
import LeadSearchForm from "./LeadSearchForm";
import DataContainer from "../../common/DataContainer";
import BookingMessages from "./BookingMessages";

export default function BookingMessagesPage() {
  return (
    <Layout>
      <HeadingContainer>
        <Heading>Сообщения о бронях (время московское)</Heading>
        <LeadSearchForm />
      </HeadingContainer>

      <DataContainer>
        <BookingMessages />
      </DataContainer>
    </Layout>
  );
}
