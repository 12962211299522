import React from "react";

export default function ModalHeader({ onClose, title }) {
  return (
    <div className="flex justify-between items-center pb-8">
      <p className="text-2xl font-bold text-indigo-600">{title}</p>
      <div className="modal-close cursor-pointer z-50" onClick={onClose}>
          <i className="fas fa-times text-gray-500"></i>
      </div>
    </div>
  );
}
