import React from "react";
import FormGroup from "../../../common/form/FormGroup";
import FormControls from "../../../common/form/FormControls";
import ErrorField from "../../../common/form/ErrorField";

export default function DeactivateForm({ crm, responseError, disableSubmit, handleSubmit }) {
  return (
    <FormGroup>
      <div className="text-lg">Вы уверены, что хотите отключить аккаунт {crm}?</div>
      <ErrorField>{responseError}</ErrorField>
      <FormControls attention submitName="Отключить" onSubmit={handleSubmit} disableSubmit={disableSubmit} />
    </FormGroup>
  );
}
