import React from "react";
import OpenModalButton from "./OpenModalButton";
import Update from "../forms/Update";

export default function UpdateModalButton({ crmAccount }) {
  const icon = <i className="fas fa-edit" title="Редактировать "></i>;

  return (
    <OpenModalButton icon buttonText={icon} title={`Редактирование аккаунта ${crmAccount.title} (${crmAccount.name})`}>
      <Update crmAccount={crmAccount} />
    </OpenModalButton>
  );
}
