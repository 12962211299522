import React from "react";
import Button from "../../../common/Button";
import { Link } from "react-router-dom";

export default function ShowMessagesButton({ crmAccount }) {
  const icon = <i className="fas fa-envelope-open-text" title="Просмотреть сообщения о бронях"></i>;

  return (
    <Link to={`/booking-messages/${crmAccount.name}`}>
      <Button icon buttonText={icon} />
    </Link>
  );
}
