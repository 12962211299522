import React from "react";
import * as Yup from "yup";
import AuthForm from "./AuthForm";
import Submit from "./Submit";

export default function Auth({ crmAccount }) {
  const data = { secretCode: "" };
  const validationSchema = Yup.object().shape({
    secretCode: Yup.string().required("Введите код авторизации"),
  });

  const formik = Submit({
    submitMethod: "post",
    submitUrl: `crm-account/${crmAccount.id}/auth`,
    data,
    validationSchema,
  });

  return (
    <AuthForm
      values={formik.values}
      errors={formik.errors}
      responseError={formik.status}
      disableSubmit={formik.isSubmitting}
      handleChange={formik.handleChange}
      handleSubmit={formik.handleSubmit}
    />
  );
}
