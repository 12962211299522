import axios from "axios";

const jwtToken = localStorage.getItem("token");

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
  headers: { Authorization: `Bearer ${jwtToken}` },
});

export default instance;
