import React from "react";
import FormField from "../../../common/form/FormField";
import FormGroup from "../../../common/form/FormGroup";
import FormLabel from "../../../common/form/FormLabel";
import FormTextArea from "../../../common/form/FormTextArea";
import FormControls from "../../../common/form/FormControls";
import ErrorField from "../../../common/form/ErrorField";

export default function AuthForm({ values, errors, responseError, disableSubmit, handleChange, handleSubmit }) {
  return (
    <FormGroup>
      <FormField>
        <FormLabel>Код авторизации:</FormLabel>
        <FormTextArea
          rows="16"
          id="secret-code"
          name="secretCode"
          placeholder="def5020004adeb09952d724300bd3c61281b61d1e5c3d8f92bafe186e4d0ad20ba5d6530761f1f5229110051b0efbf389d0102b59c2983fc6d651d577f6bad438a679b784ba337c13a7383da65f0a925cf02e5e06a03f4009293568bb8db1c7e788d8c8eb54da7c642e2ffe7a0b19316d95523e2c137ca9fc860e877e7ef452eb8573bbb199b8c0124854e627774a8916e4019b323783c006daf6f6a34a8fe92b6b5bbe6929d898392f844b575d2c12683bf4b7329f6a498323836d9885a57c60191ddacfdcf1b27ec43d560f98479a5e5169bfc064114e4770bd9c0f581fcd0e16ca01208474c30127917e452fceab7d6212967461d29076e34659fbb15990bda6430fd29bbe769d14fc87d9471f2b4ceaef4449bec9de6837e10c6c6ba2a1dd59fd68d03f28672adcfdc43a21f9d485565e79ebdee4903a0ad20616421f5a5ab7f43736b45d36669f91aa60501c21e604f1b922b5edc30a0aa7339bb8e04784abb898624a4012371fd2820aa3416e84043c327ef5865d5546f9749a6e9c044c694982722256f05bf1409296a23e7d288c96f359fedf90b7af2b3488acf5ce1bdcc818531b0815ab68e356c9f0ab5c15cf95aacfb2126b4917f1737b318"
          value={values.secretCode}
          errors={errors && errors.secretCode}
          onChange={handleChange}
        />
      </FormField>

      <ErrorField>{responseError}</ErrorField>
      <FormControls submitName="Авторизовать" onSubmit={handleSubmit} disableSubmit={disableSubmit} />
    </FormGroup>
  );
}
