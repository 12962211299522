import React from "react";

import NavItem from "./NavItem";

export default function Navigation() {
  return (
    <nav className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start mx-8">
      <ul className="flex space-x-4">
        <NavItem url="/crm-accounts">Санатории</NavItem>
        <NavItem url="/booking-messages">Сообщения о бронях</NavItem>
        {/* <NavItem url="/users">Пользователи</NavItem> */}
      </ul>
    </nav>
  );
}
