import React from "react";
import Deactivate from "../forms/Deactivate";
import OpenModalButton from "./OpenModalButton";

export default function DeactivateModalButton({ crmAccount }) {
  const icon = <i className="fas fa-ban" title="Отключить"></i>;

  return (
    <OpenModalButton icon buttonText={icon} title={`Деактивация аккаунта ${crmAccount.title} (${crmAccount.name})`}>
      <Deactivate crmAccount={crmAccount} />
    </OpenModalButton>
  );
}
