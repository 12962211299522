import React, { Component } from "react";
import LoadingDataPlug from "../../common/LoadingDataPlug";
import Table from "../../common/table/Table";
import TableBody from "../../common/table/TableBody";
import TableCell from "../../common/table/TableCell";
import TableHead from "../../common/table/TableHead";
import TableHeader from "../../common/table/TableHeader";
import httpClient from "../../../modules/httpClient";
import NoDataPlug from "../../common/NoDataPlug";
import MessageStatusBadge from "./MessageStatusBadge";
import FieldWithPopover from "../../common/form/FieldWithPopover";
import ResendModalButton from "./buttons/ResendModalButton";
import CheckBox from "./CheckBox";
import Pagination from "../../common/Pagination";
import ControlsContainer from "../../common/ControlsContainer";
export default class BookingMessagesData extends Component {
  state = {
    messages: [],
    isLoading: true,
    pageCount: 1,
  };

  fetchData() {
    const { sanatoriumId, leadId, page, perPage } = this.props;

    httpClient({
      method: "get",
      url: `/messages`,
      params: {
        sanatorium: sanatoriumId || undefined,
        lead_id: leadId || undefined,
        page: page,
        per_page: perPage,
      },
    }).then((res) => {
      this.setState({
        messages: res.data.data[0].map((message) => ({ ...message, isChecked: false })),
        pageCount: Math.ceil(res.data.data[1] / perPage),
        isLoading: false,
      });
    });
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.leadId !== prevProps.leadId) ||
      (this.props.page !== prevProps.page) ||
      (this.props.perPage !== prevProps.perPage)
    ) {
      this.fetchData();
    }
  }

  handleCheck = (e) => {
    this.setState({
      messages: this.state.messages.map((message) =>
        message.id === e.target.value ? { ...message, isChecked: e.target.checked } : message
      ),
    });
  };

  handleCheckAll = (e) => {
    this.setState({ messages: this.state.messages.map((message) => ({ ...message, isChecked: e.target.checked })) });
  };

  render() {
    const { leadId, page, perPage } = this.props;

    return this.state.isLoading ? (
      <LoadingDataPlug />
    ) : this.state.messages.length < 1 ? (
      <NoDataPlug />
    ) : (
      <>
        <ControlsContainer>
          <ResendModalButton
            messages={this.state.messages.filter((message) => message.isChecked).map((message) => message.id)}
          />
        </ControlsContainer>
        <Table>
          <TableHead>
            <TableHeader>
              <CheckBox onChange={this.handleCheckAll} />
            </TableHeader>
            <TableHeader>Санаторий</TableHeader>
            <TableHeader>Номер сделки</TableHeader>
            <TableHeader>ID брони</TableHeader>
            <TableHeader>Дата получения</TableHeader>
            <TableHeader>Дата отправки</TableHeader>
            <TableHeader>Статус отправки</TableHeader>
            <TableHeader textAlign='left'>Описание ошибки</TableHeader>
          </TableHead>
          <TableBody>
            {this.state.messages.map((message) => (
              <tr key={message.id}>
                <TableCell>
                  <CheckBox onChange={this.handleCheck} isChecked={message.isChecked} value={message.id} />
                </TableCell>
                <TableCell>{message.amoAccountName}</TableCell>
                <TableCell>
                  <a href={`https://${message.amoAccountName}.amocrm.ru/leads/detail/${message.leadId}`} className="underline text-blue-600" target="_blank" rel="noreferrer">
                    {message.leadId}
                  </a>
                </TableCell>
                <TableCell>{message.pmsBookingId}</TableCell>
                <TableCell>
                  <FieldWithPopover
                    text={new Date(message.createdAt).toLocaleString("ru-RU", { timeZone: "Europe/Moscow" })}
                    popoverText={message.originalData}
                  />
                </TableCell>
                <TableCell>
                  {new Date(message.lastAttemptedAt).toLocaleString("ru-RU", { timeZone: "Europe/Moscow" })}
                </TableCell>
                <TableCell>
                  <MessageStatusBadge status={message.sendingStatus} />
                </TableCell>
                <TableCell textAlign='left'>
                  <FieldWithPopover
                    text={message.errorDescription}
                    popoverText={message.errorData && message.errorData}
                  />
                </TableCell>
              </tr>
            ))}
          </TableBody>
        </Table>
        <Pagination queryParams={leadId ? { leadId } : undefined} currentPage={page} perPage={perPage} pageCount={this.state.pageCount} />
      </>
    );
  }
}
