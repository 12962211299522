import React from "react";
import ErrorField from "./ErrorField";

export default function FormTextInput({ id, name, value, placeholder, errors, onChange }) {
  return (
    <div className="md:w-2/3">
      <input
        className={`bg-gray-200 appearance-none border-2 ${
          errors ? "border-red-300" : "border-gray-200"
        } rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500`}
        type="text"
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      <ErrorField>{errors}</ErrorField>
    </div>
  );
}
