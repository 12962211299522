import React from "react";
import Modal from "../../../common/modal/Modal";
import Button from "../../../common/Button";

export default class OpenModalButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false };
  }

  open = () => {
    this.setState({
      isOpen: true,
    });
  };

  close = () => {
    this.setState({
      isOpen: false,
    });
  };

  render() {
    return (
      <>
        <Button {...this.props} onClick={this.open} />
        {this.state.isOpen && <Modal {...this.props} onClose={this.close} />}
      </>
    );
  }
}
