import React from "react";
import ResendForm from "./ResendForm";
import Submit from "./Submit";

export default function Resend({ messages }) {
  const data = messages;

  const formik = Submit({
    submitMethod: "post",
    submitUrl: `messages/push`,
    data,
  });

  return (
    <ResendForm
      responseError={formik.status}
      disableSubmit={formik.isSubmitting}
      handleChange={formik.handleChange}
      handleSubmit={formik.handleSubmit}
    />
  );
}
