import React from "react";
import * as Yup from "yup";
import SignInForm from "./SignInForm";
import Submit from "./Submit";

export default function SignInPage() {
  const data = { email: "", password: "" };
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Введите e-mail"),
    password: Yup.string().required("Введите пароль"),
  });

  const formik = Submit({
    submitMethod: "post",
    submitUrl: `auth/login`,
    data,
    validationSchema,
  });

  return (
    <SignInForm
      values={formik.values}
      responseError={formik.status}
      disableSubmit={formik.isSubmitting}
      handleChange={formik.handleChange}
      handleSubmit={formik.handleSubmit}
    />
  );
}
