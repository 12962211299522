import React from "react";
import { useParams } from "react-router";
import BookingMessagesData from "./BookingMessagesData";
import { useQuery } from "../../../modules/useQuery";

export default function BookingMessages() {
  const { sanatoriumId } = useParams();
  const query = useQuery();

  return (
    <BookingMessagesData
      sanatoriumId={sanatoriumId || ""}
      leadId={query.get("lead_id") || ""}
      page={parseInt(query.get("page")) || 1}
      perPage={parseInt(query.get("per_page")) || 10}
    />
  );
}
