import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import SignInPage from "./components/pages/SignInPage";
import CrmAccountsPage from "./components/pages/CrmAccountsPage";
import UsersPage from "./components/pages/UsersPage";
import BookingMessagesPage from "./components/pages/BookingMessagesPage";

export default class App extends Component {
  constructor() {
    super();
    this.state = {
      isUserAuthenticated: localStorage.getItem("token") !== null,
    };
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route path="/sign-in">
            {this.state.isUserAuthenticated ? <Redirect to="/crm-accounts" /> : <SignInPage />}
          </Route>

          <Route path="/crm-accounts">
            {this.state.isUserAuthenticated ? <CrmAccountsPage /> : <Redirect to="/sign-in" />}
          </Route>

          <Route exact path="/booking-messages/">
            {this.state.isUserAuthenticated ? <BookingMessagesPage /> : <Redirect to="/sign-in" />}
          </Route>

          <Route path="/booking-messages/:sanatoriumId/">
            {this.state.isUserAuthenticated ? <BookingMessagesPage /> : <Redirect to="/sign-in" />}
          </Route>

          <Route path="/users">{this.state.isUserAuthenticated ? <UsersPage /> : <Redirect to="/sign-in" />}</Route>

          <Route path="*">{this.state.isUserAuthenticated ? <Redirect to="/booking-messages" /> : <Redirect to="/sign-in" />}</Route>
        </Switch>
      </Router>
    );
  }
}
