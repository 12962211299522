import React from "react";
import classNames from "classnames";

const messageStatus = {
  New: "Новое",
  Sent: "Отправлено",
  SendingError: "Отправляется",
  SendingFailed: "Ошибка отправки",
};

export default function MessageStatusBadge({ status }) {
  return (
    <span
      className={
        "px-2 inline-flex text-sm leading-5 font-medium rounded-full " +
        classNames({
          "bg-gray-100 text-gray-800": status === "New",
          "bg-green-100 text-green-800": status === "Sent",
          "bg-yellow-100 text-yellow-800": status === "SendingError",
          "bg-red-100 text-red-800": status === "SendingFailed",
        })
      }
    >
      {messageStatus[status]}
    </span>
  );
}
