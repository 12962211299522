import React from "react";
import Header from "./Header";
import Body from "./Body";

export default function Layout({ children }) {
  return (
    <>
      <Header />
      <Body>{children}</Body>
    </>
  );
}
