import React from "react";

function SignOut() {
  localStorage.removeItem("token");
  window.location.reload();
}

export default function SignOutLink() {
  return (
    <div>
      <button
        type="button"
        className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium mr-8"
        onClick={SignOut}
      >
        Выйти
      </button>
    </div>
  );
}
