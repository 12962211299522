import React from "react";
import FormField from "../../../common/form/FormField";
import FormGroup from "../../../common/form/FormGroup";
import FormLabel from "../../../common/form/FormLabel";
import FormTextInput from "../../../common/form/FormTextInput";
import FormTextArea from "../../../common/form/FormTextArea";
import FormControls from "../../../common/form/FormControls";
import ErrorField from "../../../common/form/ErrorField";
import FormSelect from '../../../common/form/FormSelect';

export default function CrmForm({ values, errors, responseError, disableSubmit, handleChange, handleSubmit }) {
  return (
    <FormGroup>
      <FormField>
        <FormLabel>Название:</FormLabel>
        <FormTextInput
          id='title'
          name='title'
          placeholder={'Санаторий "Ла Пуш"'}
          value={values.title}
          errors={errors && errors.title}
          onChange={handleChange}
        />
      </FormField>

      <FormField>
        <FormLabel>Имя CRM:</FormLabel>
        <FormTextInput
          id='name'
          name='name'
          placeholder='lapush'
          value={values.name}
          errors={errors && errors.name}
          onChange={handleChange}
        />
      </FormField>

      <FormField>
        <FormLabel>Секретный ключ:</FormLabel>
        <FormTextArea
          rows='2'
          id='client-secret'
          name='clientSecret'
          placeholder='lGq1jWnTPVoiwnPSD88TITEpLb8vYItkY5aSK2PuOQoMJFdDaryvV8bjJN3b6t6N'
          value={values.clientSecret}
          errors={errors && errors.clientSecret}
          onChange={handleChange}
        />
      </FormField>

      <FormField>
        <FormLabel>ID интеграции:</FormLabel>
        <FormTextInput
          id='client-id'
          name='clientId'
          placeholder='09736c8f-0a93-4804-8e0b-221b6a465aac'
          value={values.clientId}
          errors={errors && errors.clientId}
          onChange={handleChange}
        />
      </FormField>

      <FormField>
        <FormLabel>Ссылка для перенаправления:</FormLabel>
        <FormTextInput
          id='redirect-uri'
          name='redirectUri'
          placeholder='https://www.youtube.com/watch?v=dQw4w9WgXcQ'
          value={values.redirectUri}
          errors={errors && errors.redirectUri}
          onChange={handleChange}
        />
      </FormField>

      <FormField>
        <FormLabel>Roistat API-ключ:</FormLabel>
        <FormTextInput
          id='roistat-token'
          name='roistatToken'
          placeholder='34f3094jfdQw4w9WgXcQ'
          value={values.roistatToken}
          errors={errors && errors.roistatToken}
          onChange={handleChange}
        />
      </FormField>
      <FormField>
        <FormLabel>Авторизация API:</FormLabel>
        <FormSelect
          name='authType'
          value={values.authType}
          onChange={handleChange}
        >
          <option value='none'>None</option>
          <option value='bearer'>Bearer</option>
        </FormSelect>
      </FormField>
      <FormField>
        <FormLabel>API-ключ авторизации:</FormLabel>
        <FormTextInput
          id='auth-token'
          name='authToken'
          placeholder='nOkgXh3KKGsoyODzBguxsx5cYLRSyqs+li20zzYcAtc'
          value={values.authToken}
          errors={errors && errors.authToken}
          onChange={handleChange}
        />
      </FormField>
      <ErrorField>{responseError}</ErrorField>
      <FormControls
        submitName='Сохранить'
        onSubmit={handleSubmit}
        disableSubmit={disableSubmit}
      />
    </FormGroup>
  );
}
