import React from "react";

export default function FormLabel({ children }) {
  return (
    <div className="md:w-1/3">
      <label className="block text-gray-500 font-bold  mb-1 md:mb-0 pr-4" htmlFor="crm-account-title">
        {children}
      </label>
    </div>
  );
}
