import React from "react";
import Navigation from "./Navigation";
import SignOutLink from "./SignOutLink";

export default function Header() {
  return (
    <div className="bg-gray-800 relative flex items-center justify-between h-16">
      <Navigation />
      <SignOutLink />
    </div>
  );
}
