import React from "react";
import OpenModalButton from "./OpenModalButton";
import Auth from "../forms/Auth";

export default function AuthModalButton({ crmAccount }) {
  const icon = <i className="fas fa-key" title="Авторизовать в CRM"></i>;

  return (
    <OpenModalButton icon buttonText={icon} title={`Авторизация аккаунта ${crmAccount.title} (${crmAccount.name})`}>
      <Auth crmAccount={crmAccount} />
    </OpenModalButton>
  );
}
