import React from "react";
import Heading from "../common/Heading";
import HeadingContainer from "../common/HeadingContainer";
import Layout from "../common/Layout";

export default function UsersPage() {
  return (
    <Layout>
      <HeadingContainer>
        <Heading>Пользователи</Heading>
      </HeadingContainer>
    </Layout>
  );
}
