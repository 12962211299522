import { useFormik } from "formik";
import httpClient from "../../../../modules/httpClient";

export default function Submit({ submitMethod, submitUrl, data, validationSchema }) {
  return useFormik({
    initialValues: { ...data },
    validationSchema,
    validateOnChange: false,
    onSubmit: (values, actions) => {
      actions.setSubmitting(true);

      httpClient({
        method: submitMethod,
        url: submitUrl,
        data: { ...values },
      })
        .then(() => {
          window.location.reload();
        })
        .catch((error) => {
          actions.setStatus(error.response.data.data);
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    },
  });
}
