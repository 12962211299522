import React from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import Button from "../Button";
import { usePagination, DOTS } from "../../../modules/usePagination";
import { generateQueryString } from "../../helpers/queryString";

export default function Pagination({ queryParams, currentPage, perPage, pageCount }) {
  const previous = <i className="fas fa-arrow-left" title="На предыдущую страницу"></i>;
  const next = <i className="fas fa-arrow-right" title="На следующую страницу"></i>;

  const paginationRange = usePagination({
    currentPage,
    pageCount,
  });

  if (paginationRange.length < 2) {
    return null;
  }

  return (
    <div className="w-min m-auto pt-5">
      <ul className="flex justify-around">
        <li className="w-8 text-center" key="previous">
          {currentPage === 1 || (
            <Link to={generateQueryString({ ...queryParams, perPage, page: currentPage - 1 })}>
              <Button icon buttonText={previous} />
            </Link>
          )}
        </li>

        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return (
              <li className="w-8 text-center" key={index}>
                &#8230;
              </li>
            );
          }
          return (
            <li
              className={classnames("w-8 text-center", {
                "font-bold": pageNumber === currentPage,
              })}
              key={index}
            >
              <Link to={generateQueryString({ ...queryParams, perPage, page: pageNumber })}>{pageNumber}</Link>
            </li>
          );
        })}

        <li className="w-8 text-center" key="next">
          {currentPage === pageCount || (
            <Link to={generateQueryString({ ...queryParams, perPage, page: currentPage + 1 })}>
              <Button icon buttonText={next} />
            </Link>
          )}
        </li>
      </ul>
    </div>
  );
}
