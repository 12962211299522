import { React, Component } from "react";
import httpClient from "../../../modules/httpClient";
import UpdateModalButton from "./buttons/UpdateModalButton";
import AuthModalButton from "./buttons/AuthModalButton";
import CreateModalButton from "./buttons/CreateModalButton";
import CrmAccountStatusBadge from "./CrmAccountStatusBadge";
import TableHeader from "../../common/table/TableHeader";
import TableCell from "../../common/table/TableCell";
import TableBody from "../../common/table/TableBody";
import TableHead from "../../common/table/TableHead";
import Table from "../../common/table/Table";
import Heading from "../../common/Heading";
import HeadingContainer from "../../common/HeadingContainer";
import NoDataPlug from "../../common/NoDataPlug";
import LoadingDataPlug from "../../common/LoadingDataPlug";
import Layout from "../../common/Layout";
import ShowMessagesButton from "./buttons/ShowMessagesButton";
import DeactivateModalButton from "./buttons/DeactivateModalButton";
import ActivateModalButton from "./buttons/ActivateModalButton";
import DataContainer from "../../common/DataContainer";

export default class CrmAccountsPage extends Component {
  state = {
    accounts: [],
    isLoading: true,
  };

  componentDidMount() {
    httpClient({
      method: "get",
      url: "/crm-account",
    }).then((res) => {
      const accounts = res.data.data;

      accounts.forEach((account) => {
        httpClient({
          method: "get",
          url: `/messages?sanatorium=${account.name}&page=1&per_page=1`,
        }).then((res) => {
          const lastMessage = res.data.data[0][0];
          const lastMessageDate = lastMessage ? lastMessage.createdAt : "";
          account = { ...account, lastMessageDate };

          this.setState({ accounts: [...this.state.accounts, account], isLoading: false });
        });
      });
    });
  }

  render() {
    return (
      <Layout>
        <HeadingContainer>
          <Heading>Санатории (время московское)</Heading>
          <CreateModalButton />
        </HeadingContainer>

        <DataContainer>
          {this.state.isLoading ? (
            <LoadingDataPlug />
          ) : this.state.accounts.length < 1 ? (
            <NoDataPlug additionalText="Вы можете добавить санаторий по кнопке справа." />
          ) : (
            <Table>
              <TableHead>
                <TableHeader>Название</TableHeader>
                <TableHeader>Имя CRM</TableHeader>
                <TableHeader>Статус</TableHeader>
                <TableHeader>Срок действия токена</TableHeader>
                <TableHeader>Дата последнего сообщения</TableHeader>
                <TableHeader />
                <TableHeader />
                <TableHeader />
                <TableHeader />
              </TableHead>
              <TableBody>
                {this.state.accounts.map((crm) => (
                  <tr key={crm.name}>
                    <TableCell>{crm.title}</TableCell>
                    <TableCell>{crm.name}</TableCell>
                    <TableCell>
                      <CrmAccountStatusBadge status={crm.status} />
                    </TableCell>
                    <TableCell>
                      {crm.tokenValidUntil
                        ? new Date(crm.tokenValidUntil).toLocaleString("ru-RU", { timeZone: "Europe/Moscow" })
                        : "Токен отсутствует"}
                    </TableCell>
                    <TableCell>
                      {crm.lastMessageDate
                        ? new Date(crm.lastMessageDate).toLocaleString("ru-RU", { timeZone: "Europe/Moscow" })
                        : "Нет сообщений"}
                    </TableCell>
                    <TableCell>
                      <AuthModalButton crmAccount={crm} />
                    </TableCell>
                    <TableCell>
                      <ShowMessagesButton crmAccount={crm} />
                    </TableCell>
                    <TableCell>
                      <UpdateModalButton crmAccount={crm} />
                    </TableCell>
                    <TableCell>
                      {crm.status === "Disabled" ? (
                        <ActivateModalButton crmAccount={crm} />
                      ) : (
                        <DeactivateModalButton crmAccount={crm} />
                      )}
                    </TableCell>
                  </tr>
                ))}
              </TableBody>
            </Table>
          )}
        </DataContainer>
      </Layout>
    );
  }
}
