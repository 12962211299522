import React from "react";
import OpenModalButton from "../../CrmAccountsPage/buttons/OpenModalButton";
import Resend from "../forms/Resend";

export default function ResendModalButton({ messages }) {
  return (
    <OpenModalButton
      block
      buttonText={
        <>
          <i className="fas fa-redo pr-1"></i> Повторить отправку
        </>
      }
      title={`Повторная отправка сообщения`}
    >
      <Resend messages={messages} />
    </OpenModalButton>
  );
}
